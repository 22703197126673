<template>
<section class="wrapper ">
	<div class="container px-1 py-1 py-md-4  ">



		<v-stepper v-model="e6"
							 class=" bg-gray px-0"
							 vertical>
			<h4 class="p-3 text-red"
					v-html="$t('invest3')" />
			<v-stepper-step :complete="e6 > 1"
											step="1">
				<div v-if="e6>1">
					<input class="form-check-input"
								 type="checkbox"
								 value=""
								 checked> &nbsp; {{$t('assist1')}}
				</div>

				<div v-if="e6==1"
						 class="v-stepper__step v-stepper__step--inactive px-0">
					<span class="v-stepper__step__step">1</span>

					&nbsp; {{$t('assist2')}}
				</div>

				<div v-if="e6>1">
					<br />
					<br /><b class="p-5 text-success"> {{email}}</b>
				</div>
			</v-stepper-step>

			<v-stepper-content step="1">
				<v-card color="grey lighten-1"
								class="  mb-12 p-5">


					<v-text-field v-model="email"
												persistent-hint
												:label="$t('invest50')">
					</v-text-field>
					<a style="font-size:80%"
						 href="https://wallet.fraction.re/identity">{{$t('invest51')}}</a>
				</v-card>
				<v-btn class="bg-success"
							 v-if="email!=''"
							 @click="e6 = 2">
					{{$t('next')}}
				</v-btn>

			</v-stepper-content>

			<v-stepper-step :complete="e6 > 2"
											step="2">
				<input class="form-check-input"
							 v-if="e6>2"
							 type="checkbox"
							 value=""
							 checked>
				&nbsp; {{$t('assist3')}}
				<div v-if="e6>2">
					<br />
					<br />
					<b class="p-5 text-success"
						 v-html=" (field_euro)+ '€ = '+ quantity.toFixed(2) + ' Berlcoin'" />
				</div>
			</v-stepper-step>

			<v-stepper-content step="2">
				<v-card color="grey lighten-1"
								class="  mb-12 p-5">

					{{$t('invest8')}} : <b v-html="monetary_aspect(detail.anticipation)" /> % <br />
					{{$t('invest9')}} : <b v-html="monetary_aspect(price)" /> €
					<br />
					<br />
					<v-text-field v-model="field_euro"
												outlined
												@blur="convert_berlcoin"
												:label="$t('invest15')"></v-text-field>
					{{$t('invest16')}} <b v-html="monetary_aspect(quantity)" /> Berlcoin

				</v-card>
				<v-btn class="bg-success"
							 @click="e6 = 3">
					{{$t('next')}}
				</v-btn> &nbsp;
				<a @click="e6 = e6-1"
					 class="btn btn-circle btn-outline-primary btn-sm"><i class="uil uil-arrow-left"></i></a>

			</v-stepper-content>

			<v-stepper-step :complete="e6 > 3"
											step="3">
				<input class="form-check-input"
							 v-if="e6>3"
							 type="checkbox"
							 value=""
							 checked>
				&nbsp; {{$t('paiement')}}
				<div v-if="e6>3">
					<br />
					<br /><b class="p-5 text-success"
						 v-show="!virement">{{$t('paypal')}} </b> <b class="p-5 text-success"
						 v-show="virement">{{$t('moneytransfert')}} </b>
				</div>
			</v-stepper-step>

			<v-stepper-content step="3">
				<div style="max-width:750px;"
						 class=" m-0  p-5 bg-gray">



					<v-card color="grey lighten-1"
									v-show="!virement"
									class="mb-12 p-5">

						<div ref="paypal"></div>
					</v-card>

					<v-card color="grey lighten-1"
									v-show="virement"
									class="mb-12 p-5">

						<b> {{$t('rib')}}</b> <br />


						<br />
						16798
						00001
						00008540716
						38
						TREEZOR (92641)
						<br />
						<br />
						<b>{{$t('ibanlabel')}} </b> <br />
						<br />IBAN: FR76 1679 8000 0100 0085 4071 638
						<br /> Code BIC: TRZOFR21XXX
						<br /> {{$t('titulaire')}}: <b> Fraction Real Estate </b>
						<br /><br /> <b>{{$t('bank')}} </b> <br />
						Shine 5 rue Charlot, 75003 Paris
						<br /> <br /> <i style="font-size:80%">{{$t('assist4')}}</i>
					</v-card>

				</div>

				<v-btn class="bg-primary"
							 @click="virement = !virement">
					<span v-show="!virement"> {{$t('moneytransfert')}} </span> <span v-show="virement">{{$t('paypal')}}</span>
				</v-btn>
				&nbsp;

				<v-btn class="bg-success"
							 v-show="virement"
							 @click="e6 = 4">
					{{$t('next')}}
				</v-btn> &nbsp;

				&nbsp;
				<a @click="e6 = e6-1"
					 class="btn btn-circle btn-outline-primary btn-sm"><i class="uil uil-arrow-left"></i></a>
			</v-stepper-content>

			<v-stepper-step step="4"> <input class="form-check-input"
							 v-if="e6==4"
							 type="checkbox"
							 value=""
							 checked>
				&nbsp; {{$t('assist5')}}
			</v-stepper-step>
			<v-stepper-content step="4">
				<v-card color="grey lighten-1"
								class="mb-12 p-5">


					<div v-show="!virement">
						<p v-show="transactionstate">
							{{$t('assist6')}}
							<br />
							{{$t('assist7')}}
							<br />
							<span v-show="!savestate"> {{$t('assist8')}} </span>

						</p>
						<p v-show="!transactionstate"> {{$t('assist9')}}
							&nbsp;
							<a @click="e6 = e6-1"
								 class="btn btn-circle btn-outline-primary btn-sm"><i class="uil uil-arrow-left"></i></a>
						</p>

					</div>

					<div v-show="virement && !souscription"> {{$t('invest23')}}
						<br />
						<i style="font-size:80%"
							 v-html="$t('invest53')" />
						<br /> <br />
						<v-text-field v-model="cp"
													style="width:250px;float:left;margin-right:10px;"
													class="pa-5 mr-5"
													outlined
													:label="$t('invest52')" />

						<v-text-field v-model="naissance"
													outlined
													style="width:250px;margin-right:10px;"
													:label="$t('invest24')" />


						<p>
							{{$t('assist10')}}
							<br /> {{$t('assist11')}}
						</p>

						<v-btn class="bg-success"
									 v-if="cp.length==5 && naissance.length==4"
									 v-show="virement"
									 @click="souscrire();"
									 v-html="$t('invest41')" />
						&nbsp;
						<a @click="e6 = e6-1"
							 class="btn btn-circle btn-outline-primary btn-sm"><i class="uil uil-arrow-left"></i></a>
					</div>

					<div v-show="virement && souscription"> {{$t('assist12')}}
						<br /> {{$t('assist13')}}
					</div>

				</v-card>

			</v-stepper-content>
		</v-stepper>
		<a class="bg-success my-5 btn"
			 :href="$t('link_invest')"
			 v-html="$t('assist14')" />
	</div>
</section>
</template>

<script>
export default {
	name: 'empty',
	components: {

	},
	props: {},
	data: () => ({
		virement: false,
		e6: 1,
		ex7: 'red',
		email: '',
		quantity: 0,
		price: 0,
		credit: 0,
		reward: 0,
		total: 0,
		totaleuro: 0,
		win: 0,
		field_euro: 0,
		detail: [],
		step: 1,
		price: 0,
		asset_code: "Berlcoin",
		field_euro: 500,
		clepublique: "",
		naissance: "",
		email: '',
		asset_code_fceuro: "€",
		term: 0,
		cp: '',
		transactionstate: false,
		savestate: undefined,
		sizes: [
			'0', '2', '3', '5', '7', '10',
		],
		loaded: false,
		paidFor: false,
		souscription: false,
		product: {
			price: 777.77,
			description: "Berlcoin",
			img: "https://media.berlcoin.fr/10207.jpg"
		},
		test_key: "AeNxu9t1hHXlqBzQJqNFEr-Yr2DfV1XDLq93pAwXZeS71cbZrrP1Dw_YosrqQq3GGJyU_kmJvCx69B6N",
		production_key: "ASlyYL-EF0lufGf1in7r_wfB6TwQ_YECc1hZEfgPpJ-5Y8XYFBftNYuRfgkE4tXbUxJ-j5sZIbsCxX8t",
		axios_private: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.$i18n.locale = this.$route.params.lang

		this.detail.ville = 'BERLIN'
		this.detail.adresse = "Zone A"
		this.detail.siren = '911 774 206'
		this.detail.raison_sociale = "Fraction Real Estate"
		this.detail.ppadresse = "56 rue monge  "
		this.detail.ppcode_postal = "75005"
		this.detail.ppville = "PARIS"
		this.detail.telephone = "+337 66 46 17 28"
		this.detail.email = "Berlcoin@fraction.re"
		this.detail.solde = "10000"
		this.detail.anticipation = "11.5"
		this.detail.date_maj = "2022-01-01"
		this.detail.valeur_maj = 100

		let date1 = new Date(this.detail.date_maj);
		let date2 = new Date();
		let Diff_temps = date2.getTime() - date1.getTime();
		let Diff_jours = Diff_temps / (1000 * 3600 * 24);


		this.price = parseFloat(this.detail.valeur_maj) + (Diff_jours * (parseFloat(this.detail.valeur_maj) * parseFloat(this.detail.anticipation) / 100) / 365);
		this.quantity = this.field_euro / this.price;

		const script = document.createElement("script");
		script.src =
			"https://www.paypal.com/sdk/js?&currency=EUR&client-id=" + this.production_key;
		script.addEventListener("load", this.setLoaded);
		document.body.appendChild(script);

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		setLoaded: function () {
			this.loaded = true;
			window.paypal
				.Buttons({
					createOrder: (data, actions) => {
						return actions.order.create({
							purchase_units: [{
								description: this.quantity + " Berlcoin",
								amount: {
									currency_code: "EUR",
									value: this.field_euro
								}
							}]
						});
					},
					onApprove: async (data, actions) => {
						const order = await actions.order.capture();
						this.paidFor = true;
						this.e6 = 4
						//	console.log(order);
						//		console.log(JSON.stringify(order));

						this.transactionstate = true;
						this.opt = [];
						this.opt["api"] = "buyberlcoin"
						this.opt["selection"] = 'berlcoin';
						this.opt["json"] = JSON.stringify(order);
						this.opt["debug"] = true;
						this.charge_private();
					},
					onError: err => {
						console.log(err);
						this.transactionstate = false;
					}
				})
				.render(this.$refs.paypal);

		},
		charge_private() {
			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.axios_private = response.data
			})
		},
		convert_berlcoin: function () {
			this.quantity = Number(this.field_euro) / Number(this.price);
			this.verif_nb_berlcoin();
		},
		verif_nb_berlcoin: function () {
			this.quantity = (Math.round(this.quantity * 100000) / 100000).toFixed(5);
			this.field_euro = (Math.round(this.field_euro * 100) / 100).toFixed(2);
			let taux = 1;

			//console.log(this.term)
			if (this.term == "0") taux = 0;
			if (this.term == "2") taux = 1.9;
			if (this.term == "3") taux = 5.6;
			if (this.term == "5") taux = 14.4;
			if (this.term == "7") taux = 28.5;
			if (this.term == "10") taux = 46;

			this.credit = this.quantity;
			this.reward = (Number(this.quantity) * taux / 100).toFixed(2);
			this.total = (Number(this.reward) + Number(this.quantity)).toFixed(2);
			this.totaleuro = (Number(this.total) * Number(this.price)).toFixed(2);
			this.win = (Number(this.reward) * Number(this.price)).toFixed(2);

		},
		souscrire() {
			this.opt = [];
			this.opt["api"] = "write_contract_berlcoin"
			this.opt["debug"] = false
			if (this.email.length == 56) this.opt["cle"] = this.email;
			else this.opt["cle"] = "";
			if (this.email.length != 56) this.opt["email"] = this.email;
			else this.opt["email"] = "";
			this.opt["field_euro"] = this.field_euro
			this.opt["antispam"] = this.naissance
			this.opt["antispam2"] = this.cp
			this.opt["term"] = this.term
			this.opt["token_asset_code"] = this.id
			this.$store.dispatch('post', this.opt).then(response => {})
			this.souscription = true;
		},


	},
	watch: {
		axios_private: function () {
			if (this.axios_private != undefined) {
				if (this.axios_private.data != undefined)
					if (this.axios_private.data.result == true) {
						this.savestate = true;
						this.souscrire();
					}
				else
					this.savestate = false;
			}
		},
		term() {
			this.convert_berlcoin();
		},
		field_euro() {
			this.quantity = this.field_euro / this.price;
			if (parseFloat(this.quantity) > parseFloat(this.detail.solde)) {
				this.field_euro = Math.floor(this.detail.solde * this.price * 100) / 100;
				this.quantity = this.detail.solde;
			}
		}
	}
}
</script>

<style scoped>
</style>
